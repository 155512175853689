<template>
  <el-dialog
    :append-to-body="true"
    :visible.sync="dialog"
    :title="isAdd ? '新增' : '编辑'"
    width="800px"
  >
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="150px">
      <el-form-item label="站点名称" prop="siteId">
        <el-select v-model="form.siteId" filterable placeholder="请选择" style="width: 300px;" @change="getSrCmsColumns">
        <el-option v-for="item in sites" :key="item.id" :label="item.siteNameEn+'-'+item.siteName" :value="item.id"></el-option>
      </el-select>
      </el-form-item>
      <el-form-item label="语言/language" prop="language">
        <el-select v-model="form.language" filterable placeholder="请选择" style="width: 200px;">
          <el-option
            v-for="item in languages"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="栏目名称" prop="columnId">
        <treeselect
          v-model="form.columnId"
          :options="srCmsColumns"
          style="width: 300px;"
          placeholder="选择栏目"
        />
      </el-form-item>
      <el-form-item label="组件名称" prop="moduleId">
        <el-select v-model="form.moduleId" filterable placeholder="请选择" style="width: 400px;">
          <el-option
            v-for="item in srCmsModules"
            :key="item.id"
            :label="item.moduleName+'—'+item.moduleDesc"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="附件上传">
        <el-upload
          ref="upload"
          class="upload"
          :action="uploadUrl"
          :on-success="handleSuccess"
          :limit=1
          multiple
          method:="post"
          :file-list="fileList">
          <el-button size="small" type="primary" plain>选择文件</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="附件云路径">
        <el-input type="textarea" autosize v-model="fileUrl" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="json转码">
        <el-input type="textarea" autosize v-model="jsonStr" @blur="changeJsonStr" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="组件内容" prop="moduleContent">
        <el-input type="textarea" autosize v-model="form.moduleContent" @input="changeShow" style="width: 620px;"/>
      </el-form-item>
      <el-form-item label="JOSN解析">
        <JsonViewer :value="showModuleContent" :expand-depth=5 boxed sort></JsonViewer>
      </el-form-item>

      <el-form-item label="是否首页显示" prop="isShow">
        <el-radio v-model="form.isShow" label=1>是</el-radio>
        <el-radio v-model="form.isShow" label=0>否</el-radio>
      </el-form-item>
      <el-form-item label="排序号">
        <el-input v-model="form.sortNo" style="width: 80px;"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit('form')">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import JsonViewer from 'vue-json-viewer'
import { getCmsColumnsTree } from '@/api/cms/srCmsColumn'
import { add, edit } from "@/api/cms/srCmsModuleContent";
import { uploadFile} from '@/api/system/dims'
import Treeselect from "@riophae/vue-treeselect";
import IconSelect from "@/components/IconSelect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: { Treeselect, IconSelect ,JsonViewer },
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    },
    sites: {
      type: Array,
      required: true
    },
    srCmsModules: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      srCmsColumns: [],
      showModuleContent:{
      },
      form: {
        id: "",
        siteId: "",
        language:'',
        columnId: "",
        moduleId: "",
        moduleContent: "",
        isShow: "1",
        sortNo: "",
        publishPeople: "",
        createTime: "",
        updateTime: ""
      },
      languages: [{
        value: 'zh_CN',
        label: '中文/chinese'
      }, {
        value: 'en_US',
        label: '英语/english'
      }],
      rules: {
        siteId: [
          { required: true, message: "请选择站点", trigger: "blur" },
        ],
        language: [
          { required: true, message: '请选择语言', trigger: 'blur' },
        ],
        moduleId: [
          { required: true, message: "请选择组件", trigger: "blur" },
        ],
        moduleContent: [
          { required: true, message: "请输入组件内容", trigger: "blur" },
          { min: 4, message: "长度不少于4个字符(2个汉字)", trigger: "blur" }
        ],
      },
      fileList:[],
      uploadUrl:process.env.VUE_APP_API+'community/auth/file/upload',
      fileUrl:"",
      jsonStr:"",
    };
  },
  methods: {
    cancel() {
      this.resetForm();
    },
    doSubmit(form) {
      this.loading = true;
      if (this.isAdd) {
        this.doAdd(form);
      } else this.doEdit(form);
    },
    doAdd(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          add(this.form)
            .then(res => {
              this.resetForm();
              this.$notify({
                title: "添加成功",
                type: "success",
                duration: 2500
              });
              this.loading = false;
              this.$parent.$parent.init();
            })
            .catch(err => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        } else {
          this.loading = false;
        }
      });
    },
    doEdit(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          edit(this.form)
            .then(res => {
              this.resetForm();
              this.$notify({
                title: "修改成功",
                type: "success",
                duration: 2500
              });
              this.loading = false;
              this.sup_this.init();
            })
            .catch(err => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        } else {
          this.loading = false;
        }
      });
    },
    resetForm() {
      this.dialog = false;
      this.$refs["form"].resetFields();
      this.form = {
        id: "",
        siteId: "",
        language:'',
        siteName: "",
        columnId: "",
        columnName: "",
        moduleId: "",
        moduleName: "",
        moduleContent: "",
        isShow: "1",
        sortNo: "",
        publishPeople: "",
        createTime: "",
        updateTime: ""
      };
      this.showModuleContent={};
      this.fileList=[];
      this.fileUrl="";
      this.jsonStr="";

    },
    changeJsonStr(){
      if(this.jsonStr!=''){
        this.jsonStr=JSON.stringify(this.jsonStr);
      }

    },
    changeShow(){
      this.showModuleContent=JSON.parse(this.form.moduleContent);
    },
    handleSuccess(response){
      //上传成功要处理的事
      this.fileUrl=response.data.content[0].url;
    },
    getSrCmsColumns(){
      this.formData = {
        siteId: this.form.siteId,
      }
      getCmsColumnsTree(this.formData).then(res => {
        console.log(res);
        this.srCmsColumns = [];
        const srCmsColumn = { id: 0, label: 'top栏目', children: [] };
        srCmsColumn.children = res;
        this.srCmsColumns.push(srCmsColumn);

      })
    },
  }
};
</script>

<style scoped>
</style>
