<template>
  <div class="app-container">
    <eHeader :query="query" :sites="sites" :srCmsModules="srCmsModules" />
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="内容编号" />
      <el-table-column prop="language" label="语言/language">
        <template slot-scope="scope">
          <span>{{scope.row.language=='en_US'?'English':'中文' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="siteName" label="站点名称" />
      <el-table-column prop="columnName" label="栏目名称" />
      <el-table-column prop="moduleName" label="组件名称" />
      <el-table-column prop="publishPeople" label="发布人" />
      <el-table-column prop="createTime" label="创建时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template slot-scope="scope">
          <edit
            v-if="checkPermission(['ADMIN','SRCMSMODULECONTENT_ALL','SRCMSMODULECONTENT_EDIT'])"
            :data="scope.row"
            :sup_this="sup_this"
            :sites="sites"
            :srCmsModules="srCmsModules"
          />
          <el-popover
            v-if="checkPermission(['ADMIN','SRCMSMODULECONTENT_ALL','SRCMSMODULECONTENT_DELETE'])"
            :ref="scope.row.id"
            placement="top"
            width="180"
          >
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button
                :loading="delLoading"
                type="primary"
                size="mini"
                @click="subDelete(scope.row.id)"
              >确定</el-button>
            </div>
            <el-button slot="reference" type="danger" size="mini">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"
    />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { del } from "@/api/cms/srCmsModuleContent";
import { parseTime } from "@/utils/index";
import { getModulesData } from "@/api/cms/srCmsModule";
import { getSitesData } from "@/api/cms/srSite";
import eHeader from "@/components/cms/modulecontent/header";
import edit from "@/components/cms/modulecontent/edit";
export default {
  name:'cmsstaticcontent',
  components: { eHeader, edit },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      sup_this: this,
      sites: [],
      srCmsModules: []
    };
  },
  created() {
    this.getSites();
    this.getModules();
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = "cms/crm/srCmsModuleContent";
      const sort = "id,desc";
      this.params = { page: this.page, size: this.size, sort: sort };
      const query = this.query;
      const siteId = query.siteId;
      const columnName = query.columnName;
      const moduleName = query.moduleName;
      const language = query.language;

      if (siteId !== "" && siteId !== null) {
        this.params["siteId"] = siteId;
      }
      if (columnName !== "" && columnName !== null) {
        this.params["columnName"] = columnName;
      }
      if (moduleName !== "" && moduleName !== null) {
        this.params["moduleName"] = moduleName;
      }
      if (language !== "" && language !== null) {
        this.params["language"] = language;
      }
      return true;
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then(res => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500
          });
        })
        .catch(err => {
          this.delLoading = false;
          this.$refs[id].doClose();
          console.log(err.response.data.message);
        });
    },
    getSites() {
      const params = {page:0,size:1000}
      getSitesData(params).then(res => {
        this.sites = res.content;
      });
    },
    getModules() {
      getModulesData().then(res => {
        this.srCmsModules = res.content;
      });
    }
  }
};
</script>

<style scoped>
</style>
