<template>
  <div>
    <el-button size="mini" type="success" @click="to">编辑</el-button>
    <eForm
      ref="form"
      :sup_this="sup_this"
      :is-add="false"
      :sites="sites"
      :srCmsModules="srCmsModules"
    />
  </div>
</template>
<script>
import eForm from "./form";
export default {
  components: { eForm },
  props: {
    data: {
      type: Object,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    },
    sites: {
      type: Array,
      required: true
    },
    srCmsModules: {
      type: Array,
      required: true
    }
  },
  methods: {
    to() {
      const _this = this.$refs.form;
      _this.form = {
        id: this.data.id,
        siteId: this.data.siteId,
        language: this.data.language,
        columnId: this.data.columnId,
        moduleId: this.data.moduleId,
        moduleContent: this.data.moduleContent,
        // showModuleContent: JSON.parse(this.data.moduleContent),
        isShow: this.data.isShow.toString(),
        sortNo: this.data.sortNo,
        publishPeople: this.data.publishPeople,
        createTime: this.data.createTime,
        updateTime: this.data.updateTime
      };
      this.$refs.form.getSrCmsColumns();
      _this.dialog = true;
      _this.showModuleContent=JSON.parse(this.data.moduleContent);
    }
  }
};
</script>

<style scoped>
div {
  display: inline-block;
  margin-right: 3px;
}
</style>
