<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-select v-model="query.siteId" clearable placeholder="站点名称" class="filter-item" style="width: 150px" @change="toQuery">
      <el-option v-for="item in sites" :key="item.id" :label="item.siteName" :value="item.id"/>
    </el-select>
    <el-input
      v-model="query.columnName"
      clearable
      placeholder="栏目名称"
      style="width: 200px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
    />
    <el-input
      v-model="query.moduleName"
      clearable
      placeholder="组件名称"
      style="width: 200px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
    />
    <el-select v-model="query.language" clearable placeholder="语言/language" class="filter-item" style="width: 150px" @change="toQuery">
      <el-option v-for="item in languages" :key="item.value" :label="item.label" :value="item.value"/>
    </el-select>
    <el-button
      class="filter-item"
      size="mini"
      type="primary"
      icon="el-icon-search"
      @click="toQuery"
    >搜索</el-button>
    <!-- 新增 -->
    <div style="display: inline-block;margin: 0px 2px;">
      <el-button
        v-if="checkPermission(['ADMIN','SRCMSMODULECONTENT_ALL','SRCMSMODULECONTENT_CREATE'])"
        class="filter-item"
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="$refs.form.dialog = true"
      >新增</el-button>
      <eForm
        ref="form"
        :is-add="true"
        :sites="sites"
        :srCmsModules="srCmsModules"
      />
    </div>
  </div>
</template>

<script>
import checkPermission from "@/utils/permission"; // 权限判断函数
import eForm from "./form";
export default {
  components: { eForm },
  props: {
    query: {
      type: Object,
      required: true
    },
    sites: {
      type: Array,
      required: true
    },
    srCmsModules: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      languages:[
        {"value":"en_US","label":"English"},
        {"value":"zh_CN","label":"中文"}
      ]
    };
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$parent.page = 0;
      this.$parent.init();
    }
  }
};
</script>
